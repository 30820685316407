
/** @jsx jsx */
import Link from '../components/Link'
import MySEO from '../components/seo'
import {Container, Box,jsx,Flex,Grid,Text,Heading} from 'theme-ui'
import Layout from '../components/layout'
export default function Ziele(){
    return(
        <Layout>
     <Flex>

<Container sx={{m:1,color:`text`}} >

<Box sx={{m:1}}><Heading sx={{color:'text',fontWeight:"500"}} as="h1">Unsere Ziele</Heading></Box>


<MySEO title="Unsere Ziele" description="Die Ziele von K.O.M." />
<Grid  sx={{m:1}}
 columns={[1,2,2,2,'1fr','1fr']}
 gap={4}
>
 

<Box sx={{py:0,m:0}}>

   <Text >Das Kulturnetz Oberes Mittelrheintal, kurz <span style={{'fontWeight':500}}>K.O.M.</span>, möchte einen Beitrag zum kulturellen Leben im gesamten <span style={{'fontWeight':500}}>Oberen Mittelrheintal</span>  leisten.  </Text>

  
{/* <Text>Unseren Ausgangspunkt bildet zunächst der <span style={{'fontWeight':500}}>Kunst- & Kulturraum</span> <Link sx={{color:`text`}} target='_blank' rel='noopener noreferrer' to='https://www.markt1-bacharach.de'>Markt 1</Link> in Bacharach als Modell für 
kleine, aber feine Projekte, die durch ein engagiertes <span style={{'fontWeight':500}}>Kiezmanagement</span> den <span style={{'fontWeight':500}}>Ladenleerstand im ländlichen und kleinstädtischen Raum</span> im Interesse der Gemeinschaft entschärfen und sinnvoll bespielen.  </Text>  */}
<Text >
Unseren Aktionsraum werden wir nicht begrenzen auf eine spezielle Kommune oder auf nur 
eine Seite des Rheins, sondern wir möchten <u>im gesamten Oberen Mittelrheintal aktiv werden</u>.   </Text>

<Text >
Wir wollen kulturelle Kräfte bündeln und organisieren, Know-how erarbeiten und zur Verfügung stellen in allen Fragen der <span style={{'fontWeight':500}}>Kulturarbeit rund um Kunst, Wissenschaft und Bildung.</span>   

</Text>

</Box>
<Box>
 <Text sx={{pb:2}}>Im Zuge der Coronakrise und dem bedrohlichen "Ladensterben" wünschen wir uns eine konstruktive und experimentierfreudige Zusammenarbeit mit den einzelnen Kommunen.    <br/>  
Ebenfalls wichtig ist uns der Einsatz und das Ausprobieren verschiedener <strong>digitaler Formate</strong>, um <strong>Kommunikation und Austausch unter der Bevölkerung</strong> zu stärken und zu unterstützen.</Text>
 
<Text >Im Zentrum steht ein <strong>modernes Kommunikationsmanagement</strong>, das die Akteure untereinander verbindet 
und die an Kultur interessierten Menschen <strong>effektiv informiert und einbindet</strong>. </Text>

<Text >Alles Weitere wird im Verein diskutiert und entwickelt werden. </Text>
 
<br/>

<Link to="/beitritt"> <p sx={{color:`text`,fontWeight:500}}> <span>&rarr;</span> Mitglied werden</p></Link>

</Box>
</Grid> 
</Container> 

</Flex>
</Layout>
    )
}
    



